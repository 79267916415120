import React, { useRef, useEffect, createRef } from "react";
import { gsap } from "gsap";
import Line from "../assets/svg/burgerline.inline.svg";
import { Box, Flex } from "@chakra-ui/react";

export const MenuBurger = ({ hover }) => {
	const lineTop = useRef();
	const lineCenter = useRef();
	const lineBottom = useRef();

	const lineTopTween = useRef(null);
	const lineCenterTween = useRef(null);
	const lineBottomTween = useRef(null);

	useEffect(() => {
		gsap.set(lineTop.current, { x: 14, y: 15 });
		gsap.set(lineCenter.current, { x: 14, y: 20.5 });
		gsap.set(lineBottom.current, { x: 14, y: 26 });
	}, []);

	useEffect(() => {
		lineTopTween.current = gsap.to(lineTop.current, {
			duration: 0.25,
            //transform: "translateY(-5px)",
            y: "-=5",
			scaleY: 0.8,
			paused: true,
		});
		lineBottomTween.current = gsap.to(lineBottom.current, {
			duration: 0.25,
			//transform: "translateY(5px)",
            y: "+=5",
			scaleY: 0.8,
			paused: true,
		});
		lineCenterTween.current = gsap.to(lineCenter.current, {
			scaleY: 0.8,
			paused: true,
		});
	}, []);

	useEffect(() => {
		if (hover) {
			lineTopTween.current.play();
			lineBottomTween.current.play();
			lineCenterTween.current.play();
		} else {
			lineTopTween.current.reverse();
			lineCenterTween.current.reverse();
			lineBottomTween.current.reverse();
		}
	}, [hover]);

	return (
		<Box
			boxSize="44px"
			position="relative"
			//transform="rotate(-0.0000000001deg)"
		>
			<Box
				willChange="transform"
				pos="absolute"
				ref={lineTop}
				width="15px"
				height="3px"
				background={"#fff"}
			/>
			<Box
				willChange="transform"
				pos="absolute"
				ref={lineCenter}
				width="15px"
				height="3px"
				background={"#fff"}
			/>
			<Box
				willChange="transform"
				pos="absolute"
				ref={lineBottom}
				width="15px"
				height="3px"
				background={"#fff"}
			/>
		</Box>
	);
};
