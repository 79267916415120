import React, { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import { Link } from "gatsby";
import TransitionLink from "gatsby-plugin-transition-link";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "styled-components";
import MecLogo from "../assets/svg/mec.inline.svg";
import { Flex, Box } from "@chakra-ui/react";
import { MenuBurger } from "./MenuBurger";
import { randomColor } from "../utils/random-color";
import { getLocale, getLocaleUrl, translate } from "../utils/get-locale";
import useOnClickOutside from "../utils/useOnClickOutside";

const NavStyles = styled.nav`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 12px;
	max-width: 500px;
	margin: 0 auto;
	position: relative;
	z-index: 100;
`;

const StyledAniLink = styled(AniLink)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledMecLogo = styled(MecLogo)`
	position: absolute;
	top: 24;
	z-index: 20;
`;

const PinkBall = styled.div`
	position: relative;
	--size: 138px;
	width: var(--size);
	height: var(--size);
	border-radius: calc(var(--size) / 2);
	background-color: #fcafc0;
	margin: 0 45px;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Menu = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	--size: 44px;
	width: var(--size);
	height: var(--size);
	border-radius: calc(var(--size) / 2);
	background-color: #ffc600;
	z-index: 11;
	cursor: pointer;
	will-change: transform;
	backface-visibility: hidden;
`;

const SuperBalls = styled.div`
	position: relative;
	--size: 43px;
	width: var(--size);
	height: var(--size);
	border-radius: calc(var(--size) / 2);
	background-color: #fff;
	z-index: 11;
`;

const Ball = styled.div`
	position: absolute;
	top: ${(props) => props.top}px;
	left: ${(props) => props.left}px;
	--size: 12px;
	width: var(--size);
	height: var(--size);
	border-radius: calc(var(--size) / 2);
	background-color: ${(props) => props.color};
`;

const StyledLink = styled(Link)`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 2rem;
	line-height: 1;
	color: #fff;
	margin-bottom: 50px;
`;

const LangLink = styled(StyledLink)`
	display: inline-block;
	font-size: 1.2rem;
	margin-bottom: 24px;
`;

const Nav = ({ locale, infoPage, contactPage, clickPage }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [mouseOver, setMouseOver] = useState(false);
	const menuRef = useRef(null);
	const superRef = useRef(null);
	const superBallRef1 = useRef(null);
	const superBallRef2 = useRef(null);
	const superBallRef3 = useRef(null);
	const menuTween = useRef(null);
	const pinkTween = useRef(null);
	const pinkHoverTween = useRef(null);
	const superTween = useRef(null);
	const pinkRef = useRef(null);
	const linkContainer = useRef(null);
	const superBallTween1 = useRef(null);
	const superBallTween2 = useRef(null);
	const superBallTween3 = useRef(null);

	const navRef = useRef(null);

	const handleClickOutside = () => {
		if(menuOpen) setMenuOpen(false);
	};

	useOnClickOutside(navRef, handleClickOutside);

	useEffect(() => {
		menuTween.current = gsap.to(menuRef.current, {
			duration: 0.25,
			transformOrigin: "50% 50%",
			scale: 1.2,
			paused: true,
		});
		superTween.current = gsap.to(superRef.current, {
			duration: 0.15,
			transformOrigin: "50% 50%",
			scale: 1.2,
			paused: true,
		});
		pinkTween.current = gsap.to(pinkRef.current, {
			duration: 0.5,
			transformOrigin: "50% 50%",
			scale: 6.6,
			ease: "Power3.easeOut",
			paused: true,
		});

		pinkHoverTween.current = gsap.to(pinkRef.current, {
			duration: 0.25,
			transformOrigin: "50% 50%",
			scale: 1.2,
			ease: "Power3.easeOut",
			paused: true,
		});

		superBallTween1.current = gsap.to(superBallRef1.current, {
			duration: 0.2,
			x: "+=16",
			paused: true,
		});
		superBallTween2.current = gsap.to(superBallRef2.current, {
			duration: 0.2,
			x: "-=8",
			y: "+=13",
			paused: true,
		});
		superBallTween3.current = gsap.to(superBallRef3.current, {
			duration: 0.2,
			x: "-=8",
			y: "-=13",
			paused: true,
		});
	}, []);

	useEffect(() => {
		if (menuOpen) {
			pinkTween.current.play();
			gsap.to(linkContainer.current, {
				duration: 0.25,
				autoAlpha: 1,
				delay: 0.4,
			});
		} else {
			pinkTween.current.reverse();
			gsap.to(linkContainer.current, { duration: 0.25, autoAlpha: 0 });
		}
	}, [menuOpen]);

	const onMouseEnterHandler = () => {
		setMouseOver(true);
		menuTween.current.play();
	};
	const onMouseLeaveHandler = () => {
		setMouseOver(false);
		if (!menuOpen) menuTween.current.reverse();
	};

	const pinkMouseEnter = () => {
		if (!menuOpen) pinkHoverTween.current.play();
	};

	const pinkMouseLeave = () => {
		if (!menuOpen) pinkHoverTween.current.reverse();
	};

	const superMouseEnter = () => {
		superBallTween1.current.play();
		superBallTween2.current.play();
		superBallTween3.current.play();
	};

	const superMouseLeave = () => {
		superBallTween1.current.reverse();
		superBallTween2.current.reverse();
		superBallTween3.current.reverse();
	};

	const indexLink = (loc) => {
		if (loc) return `/${locale}`;

		return getLocaleUrl() ? `/${getLocaleUrl()}` : `/`;
	};

	return (
		<NavStyles ref={navRef}>
			<Menu
				ref={menuRef}
				onMouseEnter={onMouseEnterHandler}
				onMouseLeave={onMouseLeaveHandler}
				onClick={() => setMenuOpen((state) => !state)}
			>
				<MenuBurger hover={mouseOver} />
			</Menu>
			<Box
				position="relative"
				className="navi"
				onMouseEnter={pinkMouseEnter}
				onMouseLeave={pinkMouseLeave}
			>
				<StyledAniLink paintDrip hex="#fcafc0" to={indexLink(locale)}>
					<PinkBall ref={pinkRef} />
					<StyledMecLogo alt="mec" />
				</StyledAniLink>
				<Flex
					as="nav"
					direction="column"
					alignItems="center"
					pos="absolute"
					top="150px"
					width="100%"
					visibility="hidden"
					zIndex={22}
					ref={linkContainer}
					onClick={() => {
						setMenuOpen(false);
					}}
				>
					<StyledLink
						to={
							locale
								? `/${locale}/products`
								: translate("tuotteetlink")
						}
					>
						{translate("tuotteet")}
					</StyledLink>
					<StyledLink
						to={
							locale
								? `/${locale}/${infoPage.slug}`
								: translate("meistalink")
						}
					>
						{translate("meista")}
					</StyledLink>
					<StyledLink
						to={
							locale
								? `/${locale}/${contactPage.slug}`
								: translate("yhteyslink")
						}
					>
						{translate("yhteys")}
					</StyledLink>
					{getLocale() !== "fi" ? (
						<Link to="/">
							<LangLink as="span">Sama suomeksi</LangLink>
						</Link>
					) : null}
					{getLocale() !== "en" ? (
						<Link to="/en">
							<LangLink as="span">Same in English</LangLink>
						</Link>
					) : null}
					{getLocale() !== "sv" ? (
						<Link to="/sv">
							<LangLink as="span">Samma på svenska</LangLink>
						</Link>
					) : null}
				</Flex>
			</Box>
			<StyledAniLink
				paintDrip
				hex={randomColor()}
				to={
					locale
						? `/${locale}/${clickPage.slug}`
						: translate("clicklink")
				}
			>
				<SuperBalls
					ref={superRef}
					onMouseEnter={superMouseEnter}
					onMouseLeave={superMouseLeave}
				>
					<Ball
						color={"#fcafc0"}
						top={21}
						left={8}
						ref={superBallRef1}
					/>
					<Ball
						color={"#523178"}
						top={8}
						left={16}
						ref={superBallRef2}
					/>
					<Ball
						color={"#ff8f1c"}
						top={21}
						left={24}
						ref={superBallRef3}
					/>
				</SuperBalls>
			</StyledAniLink>
		</NavStyles>
	);
};

export default Nav;
